export default function Product( {product} ) {
    return (
        <div className="single-flavour-item">
            <div className={'single-flavour ' + product.identifier + ' uk-card uk-card-default uk-card-body uk-text-left uk-padding-remove uk-height-medium'}>
                <div className="single-flavour-background uk-background-cover" data-src={process.env.PUBLIC_URL + '/img/splash/' + product.identifier + '.png'} uk-img=""></div>
                <div className="character">
                    <img src={process.env.PUBLIC_URL + '/img/characters/' + product.identifier + '.png'} alt={product.name} title={product.name}/>
                </div>
                <div className="uk-position-bottom uk-padding-small uk-text-center">
                    <div className="options">
                        <div>
                            <h3>{product.name}</h3>
                            <p>{product.description}</p>
                        </div>
                        
                        <button className={'uk-button uk-border-pill uk-box-shadow-large uk-button-' + product.identifier + (!product.instock ? ' disabled' : '')}>{product.instock ? 'Add to cart' : 'Out of stock'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}