import './App.css';
import { useEffect, useState } from 'react'
import Product from './Products';

function App() {
	const[ products, setProducts ] = useState( [] );
	const[ loading, setLoading ] = useState( true );

	useEffect(() => {

		fetch('products.json').then(response => {
			console.log(response);
            response.json().then(data => {
                setProducts(data);
				setLoading( false );
            })
        })
	}, [] );

	return (
		<>
			<div className="uk-container">
				<nav className="uk-navbar-container uk-width-1-1 uk-navbar-transparent top-nav-bar uk-background-default" uk-navbar="">
					<div className="uk-navbar-left">
						<a className="uk-navbar-item" href="#" aria-label="Back to Home">
							<img src={process.env.PUBLIC_URL + '/img/logo.svg'} className="logo-image"/>
						</a>
						<ul className="uk-navbar-nav normal-text top-nav uk-visible@s">
							<li className="uk-active"><a href="">Our Story</a></li>
							<li><a href="">Track Order</a></li>
						</ul>
					</div>
					<div className="uk-navbar-right">
						<a href="" uk-icon="icon:bag; ratio: 1.5"></a>
						<a href="#" className="uk-navbar-toggle uk-hidden@s" uk-navbar-toggle-icon="" uk-toggle="target: #sidenav"></a>
					</div>
				</nav>
				<div id="sidenav" uk-offcanvas="flip: true" className="uk-offcanvas">
					<div className="uk-offcanvas-bar">
						<ul className="uk-nav">
							<li><a className="uk-text-large" href="">Our Story</a></li>
							<li><a className="uk-text-large" href="">Track Order</a></li>
						</ul>
					</div>
				</div>
				<div className="uk-flex uk-flex-middle header">
					<div className="title-container">
						<h1 className="title">Taste the Difference.</h1>
						<p className="sub-title">Twende Pwani</p>
						<p uk-margin="">
							<a className="uk-button uk-button-orange uk-border-pill order-button" href="#shop" uk-scroll="">Order Now</a>
						</p>
					</div>
					<div className="header-image uk-visible@s">
						<img src={process.env.PUBLIC_URL + '/img/grouped-photo.png'} />
					</div>
				</div>
				<div className="uk-container uk-margin-large-top pack-section">
					<div className="uk-grid-match uk-grid-small uk-child-width-expand@m uk-text-center uk-margin-large-top" uk-grid="">
						<div className="uk-grid-match uk-grid-small uk-child-width-1-2 uk-child-width-1-1@s" uk-grid="">
							<div className="uk-width-1-2">
								<img src={process.env.PUBLIC_URL + '/img/bg/blueberry.png'} />
							</div>
							<div className="uk-width-1-2">
								<img src={process.env.PUBLIC_URL + '/img/bg/coconut.png'} />
							</div>
							<div className="uk-width-1-2">
								<img src={process.env.PUBLIC_URL + '/img/bg/grape.png'} />
							</div>
							<div className="uk-width-1-2">
								<img src={process.env.PUBLIC_URL + '/img/bg/strawberry.png'} />
							</div>
						</div>
						<div>
							<div className="uk-card uk-background-default uk-card-body  uk-border-rounded">
								<div className="header">
									<h2 className="title">Tropical Flavours.</h2>
									<img className="floating-title" src={process.env.PUBLIC_URL + '/img/characters/strawberry.png'} />
								</div>
								<div className="uk-text-left">
									<p>mabuyu.co.ke was born to bring back the good name to the baobab flavoured treat. With mouth watering flavours, you won't want to eat anything else.</p>
									<p>We have a delightful mix of flavours from the baobab tree. Some of our flavours include strawberry, orange, blueberry, coconut, grape and pinneaple.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loading ? (
					<div className="uk-container uk-margin-large-top flavour-section uk-text-center" id="shop">
						<span className="uk-margin-small-right" uk-spinner="ratio: 3"></span>
					</div>
				) : (
					<>
					<div className="uk-container uk-margin-large-top flavour-section" id="shop">
						<div className="header">
							<h1 className="title">MEET OUR FLAVORS</h1>
						</div>
						<div className="uk-grid-match uk-grid-large uk-child-width-1-2@s uk-child-width-1-3@m uk-text-left product-row" uk-grid="">
							{products.map(function (product, index) {
								return <Product key={index} product={product} />;
							})}
						</div>
					</div>
					<div className="uk-container uk-margin-large-top uk-align-center uk-text-center home-checkout">
						<a className="uk-button checkout-button uk-flex-inline uk-flex-center uk-flex-middle disabled">
							<span>Ksh 0.00</span>
							<hr className="uk-divider-vertical" />
							<span>Checkout</span>
						</a>
					</div>
					</>
				)}
			</div>
			<div className="uk-container uk-align-center uk-margin-large-top uk-background-secondary uk-width-1-1 uk-padding uk-margin-remove-bottom">
				<div className="uk-container uk-margin-auto-left uk-margin-auto-right uk-grid-small uk-child-width-expand@s footer" uk-grid="">
					<div>
						<div>
							<a href="" aria-label="Back to Home">
								<img src={process.env.PUBLIC_URL + '/img/logo.svg'} className="logo"/>
							</a>
							<div>
								<a href="https://www.instagram.com/mabuyu.co.ke/" rel="noreferrer" target="_blank" className="text-white" uk-icon="icon: instagram"></a>
							</div>
						</div>
					</div>
					<div>
						<div>
							<h6 className="title text-white">INFO</h6>
							<ul className="uk-list">
								<li><a className="text-white" href="">About Us</a></li>
								<li><a className="text-white" href="">Contact</a></li>
								<li><a className="text-white" href="">Shipping</a></li>
							</ul>
						</div>
					</div>
				</div>
				
			</div>
		</>
	);
}

export default App;
